//dependencias
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
//import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
//import styled from "styled-components";
import { saveCliente, saveToken, saveBotonMensaje} from '../../accion/accion'
//componentes

//imagenes
import logo1 from "../../imagenes/Logo.png";

//css
import "./Header.css";

class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajeOn: false,
      flotadorOn: false,
    };
  }

  activarChat = () => {
    if (this.state.flotadorOn) {
      this.setState({ flotadorOn: false });
    } else {
      this.setState({ flotadorOn: true });
    }
  };
  
  componentDidMount(){
    // let idprofesor='26';
    // socket.on(idprofesor, (data) => {
    //   this.props.saveBotonMensaje(true);
    //   //console.log("paso")
    // });
    // return () => {
    //   socket.off();
    // };
  }


  render() {

    const has_message  = this.props.botonMen.botonMensaje;
    //console.log(has_message)
    return (
      <div>
        <div className="cont-cabecera row">
          <div className="col-cabecera col-12">
            <div className="logo1-cabecera col-2 ">
              <img className="logo1" src={logo1} alt="" />
            </div>
            <div className="usu-cabecera col-8">
              {/* <LinkContainer>
                <Link to="/chat">
                  {has_message === true && <BadgeNotification />}
                  <FeatherIcon icon="mail" size="10" stroke="#888" />
                </Link>
              </LinkContainer> */}

              {this.props.usuario1.password ? (
                <span className="usuario">
                  Usuario: {this.props.usuario1.usuario}{" "}
                </span>
              ) : (
                <span className="usuario">Usuario: </span>
              )}
            </div>
            <div className="logo1-cabecera col-2 ">
              <img className="logo1" src={logo1} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje

}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderNav);

// const LinkContainer = styled.div`
//   a {
//     position: relative;
//     float: right;

//     text-align: center;
//     background: #fff;

//     border-radius: 50%;
//     width: 30px;
//     height: 30px;
//     text-decoration: none !important;
//     color: inherit !important;
//     font-size: 12px;
//     padding-top: 5px;
//     svg {
//       width: 20px;
//       height: 20px;
//       stroke: #888 !important;
//       transition: all 0.3s ease;
//     }
//     :hover svg {
//       opacity: 0.8;
//     }
//   }
// `;

// const BadgeNotification = styled.div`
//   position: absolute;
//   width: 8px;
//   height: 8px;
//   background-color: #f00;
//   border-radius: 50%;
//   top: -2px;
//   right: -1px;
// `;
