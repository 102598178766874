import React, { Component } from 'react';

//componentes
import GananciaPerdida from './gananciaP'

class Index extends Component {

    render(){
        return(
            <div>
                <GananciaPerdida />
            </div>
        );
    }

}
export default Index;