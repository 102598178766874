import axios from 'axios';

import {API, API_PRINT} from './main'

//verificar o crear token a usuarios en el sistema

//crear token usuario nuevo
export const CreateCodigoContable = async (data) => {
  let response = await API.post("/contabilidad.php", data);
  return response;
};

export const UpdateCodigoContable = async (id, data) => {
    let response = await API.patch("/contabilidad.php", data);
    return response;
  };

export const DeleteCodigoContable = async (data) => {
    let response = await API.post("/contabilidad.php", data);
    return response;
  };

  export const ListCodigoContable = async (data) => {
    let response = await API.post("/contabilidad.php", data);
    return response;
  };

  export const RetrieveCodigoContable = async (id, data) => {
    let response = await API.delete("/contabilidad.php", data);
    return response;
  };


