import axios from "axios";

const URL = process.env === 'development' ? 'http://localhost:8000/backend/':'https://institutobilinguelasnaciones.com/backend'
const URL_PRINT = process.env === 'development' ? 'http://localhost:8000/fpdf/reporte':'https://institutobilinguelasnaciones.com/fpdf/reporte'

export const API = axios.create({
    baseURL: URL,
    timeout: 1000,
    headers: { "Content-Type": "text/html" }
  });

  
export const API_PRINT = axios.create({
    baseURL: URL_PRINT,
    timeout: 1000,
    responseType: "arraybuffer",
    headers: { "Content-Type": "text/html" }
  });




