import React, { Component } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
//import axios from "axios";
import Alert from "../../modal/alert";
import { ListCodigoContable } from "../../API/Contabilidad";
//import {API, API_PRINT} from './main'
//import AdjuntarEvaluacion from "../subirPublicarNotas/subirPublicarNotas";

//componentes

function ConsultarCodigosContables(props) {
  const [mensajealerta, setMensajealerta] = React.useState(false);
  const [tituloM, setTituloM] = React.useState("");
  const [cuerpoM, setCuerpoM] = React.useState("");
  const [codigosC, setCodigosC] = React.useState([]);

  const EnviarMensaje = async () => {};
  const InitData = async () => {
    let form = new FormData();
    form.append("boton", "buscarCodigosContables");
    let res = await ListCodigoContable(form);
    if (res) {
      let icodigosC = res.data.variable2;
      setCodigosC(icodigosC);
    }
  };

  const handleClose = () => {
    setMensajealerta(false);
  };
  const consultaIndividual = () => {
   
  };

  React.useEffect(() => {
    InitData();
  }, [props.indexar]);
//console.log(props.indexar)
  return (
    <div>
      <Container>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta} 
            handleClose={handleClose}
          />
        )}
        <Row>
          <Col>
            <ContainerTabla>
              <ContainerLista>
                <Tabla>
                  <Thead>
                    <Trhead>
                      <Thhead1>CODIGO CONTABLE</Thhead1>
                      <Thhead2>NOMBRE DE LA CUENTA</Thhead2>
                      {/* <th className="titucxc3"><FeatherIcon icon="search" size="20px" stroke="#999" /></th> */}
                    </Trhead>
                  </Thead>
                  {codigosC.map((item, i) => {
                    return (
                      <Trbody
                        key={item.variable1}
                        id="tabla"
                        //onClick={consultaIndividual}
                        onClick={()=>props.obtener(item.variable1, item.variable2, item.variable3, item.variable5)}
                      >
                        <Tdbody1>{item.variable6}</Tdbody1>
                        {item.variable5 === "1" ? (
                          <Tdbody2>{item.variable3}</Tdbody2>
                        ) : (
                          <Tdbody3>{"  " + item.variable3}</Tdbody3>
                        )}
                      </Trbody>
                    );
                  })}
                </Tabla>
              </ContainerLista>
            </ContainerTabla>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(ConsultarCodigosContables);

const Container = styled.div`
  height: 70vh;
`;
const ContainerTabla = styled.div`
  color: rgb(167, 167, 167);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80% !important;
  justify-content: center;
`;
const ContainerLista = styled.div`
  background-color: #e7f3ce;
  height: 340px;
  width: auto;
  overflow: auto;
`;
const Tabla = styled.table`
  color: #fff;
  background-color: #fff;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;
  height: 20px;
  tr:nth-child(even) {
    background-color: #028d7b;
  }
  thead tr th {
    position: sticky;
    top: 0;
    z-index: 10;
    //background-color: #ffffff;
  }

  .table-responsive {
    height: 200px;
    overflow: scroll;
  }
`;
const Thead = styled.thead``;
const Trhead = styled.tr``;
const Thhead1 = styled.th`
  background-color: #2bbbad;
  color: #fff;
  font-weight: bold;
  height: 40px;
  text-align: center;
  width: 100px;
  font-size: 20px;
`;
const Thhead2 = styled.th`
  background-color: #2bbbad;
  color: #fff;
  font-weight: bold;
  height: 40px;
  text-align: center;
  width: 300px;
  font-size: 20px;
`;
const Thhead3 = styled.th`
  background-color: #2bbbad;
  color: #fff;
  font-weight: bold;
  height: 40px;
  text-align: center;
  width: 150px;
`;
const Trbody = styled.tr`
  background-color: #11a794;
`;
const Tdbody1 = styled.td`
  text-align: left !important;
  font-size: 20px;
`;
const Tdbody2 = styled.td`
  text-align: left !important;
  font-size: 20px;
  color: red;
  font-weight: bold;
  width: 70px !important;
`;
const Tdbody3 = styled.td`
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  color: #55ff33;
  font-weight: bold;
  width: 70px;
`;
const Send = styled.button`
  margin-top: 20px;
  margin-right: 10px;
  border-radius: 3px;
  width: 30%;
  height: 70%;
  border: solid 1px #eee;
  background-color: #028d7b;
  text-align: center;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  svg {
    width: 20px;
    stroke: #ffff;
  }
`;