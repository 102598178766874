import axios from 'axios';

import {API, API_PRINT} from './main'

//verificar o crear token a usuarios en el sistema

//crear token usuario nuevo
export const CrearTokenUsuarioNuevo = async (data) => {
  let response = await API.post("/maternal.php", data);
  return response;
};

//Buscar usuario nuevo
export const BuscarUsuario = async (data) => {
  let response = await API.post("/valida.php", data);
  return response;
};

//crear clave usuario nuevo
export const CrearClaveUsuarioNuevo = async (data) => {
  let response = await axios.post("/usuario.php", data);
  return response;
};

