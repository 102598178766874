import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//componentes
import "./comprobante.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class Comprobante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      flotadorCOn: false,
      irutas: [],
      cuenta: [],
      comprobante: [],
      ireferencia: [],
      ireferencia2: [],
      idia: [],
      imes: [],
      ianio: [],
      icomprobante: [],
      guardarOn: false,
      descripcion: "",
      debe: "",
      haber: "",
      montoD: 0,
      montoH: 0,
      fecha: moment().format("YYYY-MM-DD"),
      referencia: "",
      referencia2: "",
      mes: moment().format("MM"),
      anio: moment().format("YYYY"),
      consultarOn: false,
      consultarOn2: false,
      paso: false,
      numeroC: "",
      tcomprobante: "",
      itemC: "",
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      cuentaContable: "",
      flotadorCOn: false,
      guardarOn: false,
      descripcion: "",
      debe: "",
      haber: "",
      montoD: 0,
      montoH: 0,
      fecha: moment().format("YYYY-MM-DD"),
      referencia: "",
      referencia2: "",
      mes: moment().format("MM"),
      anio: moment().format("YYYY"),
      consultarOn: false,
      consultarOn2: false,
      icomprobante: [],
      comprobante: [],
      paso: false,
      numeroC: "",
      tcomprobante: "",
      itemC: "",
    });
    this.borrarComprobanteT();
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarComprobanteT = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "buscarComprobanteManualT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcomp = res.data.variable2;
      this.setState({
        comprobante: xcomp,
        montoD: res.data.variable3,
        montoH: res.data.variable4,
      });
    });
  };

  borrarComprobanteT = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "borrarComprobanteManualT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      //let xcomp = res.data.variable2;
      this.setState({});
    });
  };

  guardar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("codigo", this.state.cuentaContable);
    data.append("fecha", this.state.fecha);
    data.append("descripcion", this.state.descripcion);
    data.append("debe", this.state.debe);
    data.append("haber", this.state.haber);
    data.append("boton", "guardarComprobanteManualT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.buscarComprobanteT();
          this.setState({
            debe: "",
            haber: "",
          });
        } else {
          this.setState({
            guardarOn: true,
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo no se pudo guardar, verifique por favor",
          });
        }
      });
  };

  guardarAsientoManual = () => {
    if (this.state.montoD === this.state.montoH) {
      if (this.state.referencia.length > 0) {
        this.setState({
          loading: true,
        });
        let data = new FormData();
        data.append("claveUsu", this.props.usuario1.password);
        data.append("referencia", this.state.referencia);
        data.append("boton", "guardar");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            this.setState({
              loading: false,
            });

            if (res.data.variable1) {
              this.limpiar();
              this.buscarComprobanteT();
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Crear Comprobantes.",
                cuerpoM: res.data.variable2,
              });
            } else {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Crear Comprobantes.",
                cuerpoM: res.data.variable2,
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Cuentas por Pagar.",
          cuerpoM: "Debe seleccionar una referencia.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Cuentas por Pagar.",
        cuerpoM: "El comprobante no esta cuadrado, verifique.",
      });
    }
  };

  modificar = () => {
    let data = new FormData();
    data.append("item", this.state.itemC);
    data.append("codigo", this.state.cuentaContable);
    data.append("fecha", this.state.fecha);
    data.append("descripcion", this.state.descripcion);
    data.append("debe", this.state.debe);
    data.append("haber", this.state.haber);
    data.append("boton", "modificarAsientoManual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.consultaAsientosMa(this.state.numeroC);
          this.buscarComprobanteT();
          this.setState({
            debe: "",
            haber: "",
            itemC: "",
            descripcion: "",
            cuentaContable: "",
          });
        } else {
          this.setState({
            guardarOn: true,
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo no se pudo Modificar, verifique por favor",
          });
        }
      });
  };

  consultarReferencia = () => {
    let data = new FormData();
    data.append("boton", "buscarReferencia");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xrefe = res.data.variable1;
      this.setState({
        ireferencia: xrefe,
        ireferencia2: xrefe,
      });
    });
  };

  consultaAsientosMa = (e) => {
    let data = new FormData();
    data.append("tcomprobante", this.state.tcomprobante);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("numeroC", e);
    data.append("mes", this.state.mes);
    data.append("anio", this.state.anio);
    data.append("boton", "buscarAsientosIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcomp = res.data.variable1;
      this.buscarComprobanteT();
      this.setState({
        numeroC: e,
        consultarOn2: false,
        paso: true,
      });
    });
  };

  consultarAsientoManual = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("tcomprobante", this.state.tcomprobante);
    data.append("referencia", this.state.referencia2);
    data.append("mes", this.state.mes);
    data.append("anio", this.state.anio);
    data.append("boton", "buscarAsientosReferencia");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcomp = res.data.variable1;
      this.setState({
        consultarOn: false,
        consultarOn2: true,
        icomprobante: res.data.variable1,
      });
    });
  };

  consultarItemAsientosMa = (e) => {
    if (e > 0) {
      let data = new FormData();
      data.append("item", e);
      data.append("boton", "buscarItemAsientoManual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            itemC: e,
            cuentaContable: res.data.variable2,
            fecha: res.data.variable3,
            descripcion: res.data.variable4,
            debe: res.data.variable5,
            haber: res.data.variable6,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asiento Contable.",
        cuerpoM:
          "Para modificar un asiento debe ser de un comprobante ya Guardado, en caso contrario elimine el asiento.",
      });
    }
  };

  consultarOn = () => {
    if (this.state.consultarOn) {
      this.setState({
        consultarOn: false,
      });
    } else {
      this.setState({
        consultarOn: true,
      });
    }
  };
  consultarOn2 = () => {
    if (this.state.consultarOn2) {
      this.setState({
        consultarOn2: false,
      });
    } else {
      this.setState({
        consultarOn2: true,
      });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          idia: xdia,
          imes: xmes,
          ianio: xanio,
        });
      }
    });
  }
  eliminarCompM = (e) => {
    let data = new FormData();
    data.append("comprobanteM", e);
    data.append("boton", "eliminarComprobanteManual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarAsientoManual();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo No se pudo Eliminar, Verifique.",
          });
        }
      });
  };

  eliminar = (e) => {
    let data = new FormData();
    data.append("idcomprobanteT", e);
    data.append("boton", "eliminarComprobanteManualT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarComprobanteT();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo No se pudo Eliminar, Verifique.",
          });
        }
      });
  };

  imprimirCExcel = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("numeroC", this.state.numeroC);
    data.append("referencia", this.state.referencia2);
    data.append("tcomprobante", this.state.tcomprobante);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/impresionComprobanteExcelManual.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Comprobante.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  imprimirC = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("numeroC", this.state.numeroC);
    data.append("referencia", this.state.referencia2);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/impresionComprobanteManual.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  componentDidMount() {
    this.cargarCuentas();
    this.borrarComprobanteT();
    this.consultarReferencia();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ASIENTOS CONTABLES. Comp. No. {this.state.numeroC}</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {/* {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )} */}

          {this.state.consultarOn && (
            <div className="cont-tablanomina row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Consulta de Asientos Contables{" "}
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.consultarOn();
                    }}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-nomina"></span>

                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    {/* <div className="contenedorcedula col-3"> */}
                    <select
                      id="tcomprobante"
                      name="tcomprobante"
                      value={this.state.tcomprobante}
                      onChange={this.onChange.bind(this)}
                    >
                      <option> -- Tipos de Comprobante --</option>
                      <option value="1">Manual.</option>
                      <option value="2">Cuentas por Pagar.</option>
                      <option value="3">Compras.</option>
                      <option value="4">Nomina.</option>
                    </select>
                    {this.state.tcomprobante === "1" && (
                      <select
                        id="referencia2"
                        name="referencia2"
                        value={this.state.referencia2}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Referencia --</option>
                        {this.state.ireferencia2.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    {/* </div>
                        <div className="contenedorcedula col-7"> */}
                    <select
                      id="mes"
                      name="mes"
                      value={this.state.mes}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.imes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="anio"
                      name="anio"
                      value={this.state.anio}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.ianio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <button
                      className={this.state.guardarOn ? "botonOf" : "boton"}
                      onClick={this.consultarAsientoManual.bind(this)}
                      disabled={this.state.guardarOn}
                    >
                      Consultar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.consultarOn2 && (
            <div className="cont-tablanomina row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Consulta de Asientos Contables Manuales
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.consultarOn2();
                    }}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-nomina"></span>

                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="ititucxc2">FECHA</th>
                          <th className="ititucxc3">REFERENCIA</th>
                          {this.state.tcomprobante === "1" && (
                            <th className="ititucxc4">
                              <FeatherIcon
                                icon="delete"
                                size="20px"
                                stroke="#999"
                              />
                            </th>
                          )}
                        </tr>
                      </thead>
                      {this.state.icomprobante.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="rtotalescxc1"
                              width="10"
                              onClick={this.consultaAsientosMa.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable3}
                            </td>
                            <td
                              className="rtotalescxc1"
                              width="10"
                              onClick={this.consultaAsientosMa.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable2 + " Comp. No. " + item.variable1}
                            </td>
                            {this.state.tcomprobante === "1" && (
                              <td className="xcerr">
                                {this.state.paso === false && (
                                  <span
                                    className="cerrarme1"
                                    onClick={this.eliminarCompM.bind(
                                      this,
                                      item.variable1
                                    )}
                                  ></span>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                      <tr className="odd" key="0" id="tabla">
                        <td
                          className="rtotalescxc1"
                          width="10"
                          colSpan="2"
                          onClick={this.consultaAsientosMa.bind(this, "0")}
                        >
                          Todos los Comprobantes
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1 col-6">
              <select
                id="cuentaContable"
                name="cuentaContable"
                value={this.state.cuentaContable}
                onChange={this.onChange.bind(this)}
              >
                <option>Cuenta Contable</option>

                {this.state.cuenta.map((item, i) => {
                  return (
                    <option key={i} value={item.variable2}>
                      {item.variable6 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
            {this.state.itemC === "" && (
              <div className="contenedorcedula col-3">
                <select
                  id="referencia"
                  name="referencia"
                  value={this.state.referencia}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Referencia --</option>
                  {this.state.ireferencia.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="descripcion"
                id="descripcion"
                autoComplete="off"
                placeholder="Descripcion Asiento"
                maxLength="60"
                onChange={this.onChange.bind(this)}
                value={this.state.descripcion.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="debe"
                id="debe"
                autoComplete="off"
                placeholder="Monto Debe."
                maxLength="10"
                readOnly={this.state.haber.length > 0 ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.debe}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="haber"
                id="haber"
                autoComplete="off"
                placeholder="Monto Haber."
                maxLength="10"
                readOnly={this.state.debe.length > 0 ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.haber}
              />
            </div>

            <div className="contenedorcedula3 col-2">
              {this.state.paso === false && (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={this.guardar.bind(this)}
                  disabled={this.state.guardarOn}
                >
                  Agregar
                </button>
              )}
              {this.state.itemC !== "" && (
                <button className="boton" onClick={this.modificar.bind(this)}>
                  Modificar
                </button>
              )}
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="ititucxc1">CODIGO</th>
                    <th className="ititucxc2">FECHA</th>
                    <th className="ititucxc3">DESCRIPCIÓN</th>
                    <th className="ititucxc2">DEBE</th>
                    <th className="ititucxc2">HABER</th>
                    <th className="ititucxc4">
                      <FeatherIcon icon="delete" size="20px" stroke="#999" />
                    </th>
                  </tr>
                </thead>
                {this.state.comprobante.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="rtotalescxc1"
                        width="10"
                        onClick={this.consultarItemAsientosMa.bind(
                          this,
                          item.variable8
                        )}
                      >
                        {item.variable7}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarItemAsientosMa.bind(
                          this,
                          item.variable8
                        )}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxc2"
                        width="10"
                        onClick={this.consultarItemAsientosMa.bind(
                          this,
                          item.variable8
                        )}
                      >
                        {" "}
                        {item.variable4}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarItemAsientosMa.bind(
                          this,
                          item.variable8
                        )}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarItemAsientosMa.bind(
                          this,
                          item.variable8
                        )}
                      >
                        {item.variable6}
                      </td>
                      <td className="xcerr">
                        {this.state.paso === false && (
                          <span
                            className="cerrarme1"
                            onClick={this.eliminar.bind(this, item.variable1)}
                          ></span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="totalC col-8">
              <span>******* TOTALES *******</span>
            </div>
            <div className="totalC col-2">{this.state.montoD.toFixed(2)}</div>
            <div className="totalC col-2">{this.state.montoH.toFixed(2)}</div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-12">
              {this.state.paso === false && (
                <button
                  className="boton"
                  onClick={this.guardarAsientoManual.bind(this)}
                >
                  Guardar
                </button>
              )}
              <button className="boton" onClick={this.consultarOn.bind(this)}>
                Consultar
              </button>
              {this.state.paso !== false && (
                <button className="boton" onClick={this.imprimirC.bind(this)}>
                  Imprimir Pdf
                </button>
              )}
              {this.state.paso !== false && (
                <button
                  className="boton"
                  onClick={this.imprimirCExcel.bind(this)}
                >
                  Imprimir Excel
                </button>
              )}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Comprobante);
