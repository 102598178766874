export const saveCliente =(username,clav,nivel) => {
    return{
        type: 'SET_CLIENTE',
        usuario: username,
        password: clav,
        nivel: nivel 
        
    }
}

export const outCliente1 =() => {
    return{
        type: 'OUT_CLIENTE',   
    }
}

export const saveToken =(token) => {
    return{
        type: 'SET_TOKEN',
        token: token
    }
}

export const outToken =() => {
    //console.log('token')
    return{
        type: 'OUT_TOKEN',   
    }
}

export const saveBotonMensaje =(botonMensaje) => {
    return{
        type: 'SET_BOTONMENSAJE',
        botonMensaje: botonMensaje
    }
}

export const outBotonMensaje =() => {
    //console.log('token')
    return{
        type: 'OUT_BOTONMENSAJE',   
    }
}