import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./cierreMC.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      concepto: "",
      iconcepto: [],
      dia: [],
      mes: [],
      anio: [],
      cuenta: [],
      listaOn: false,
      activar: "",
      codigo3: "",
      codigo4: "",
      tipoCierre: "",
      cuentaContable:"",
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      concepto: "",
      listaOn: false,
      activar: "",
      codigo3: "",
      codigo4: "",
      tipoCierre: "",
      cuentaContable:"",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarCierre(e.target.value);
  }

  guardar = () => {
    let data = new FormData();
    data.append("anio", this.state.codigo4);
    data.append("mes", this.state.codigo3);
    data.append("cuentaC", this.state.cuentaContable);
    data.append("tipoCierre", this.state.tipoCierre);
    data.append("boton", "guardar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/cierreContable.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.buscarCierre(this.state.tipoCierre);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre Contable.",
            cuerpoM: "Se guardo correctamente.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre Contable.",
            cuerpoM: "Ocurrio un problema para guardar el registro.",
          });
        }
      });
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("tipoCierre", this.state.tipoCierre);
      data.append("codigo", e);
      data.append("boton", "eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/cierreContable.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre Contable.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.buscarCierre(this.state.tipoCierre);
        } else {
          this.buscarCierre(this.state.tipoCierre);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre Contable.",
            cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el registro.",
          });
        }
      });
    }
  };

  buscarCierre = (e) => {
    let data = new FormData();
    data.append("tipoCierre", e);
    data.append("boton", "consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/cierreContable.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable1;

        this.setState({
          iconcepto: xiconcepto,
        });
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  componentDidMount() {
    this.cargarCuentas();
    this.cargarFechas();
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Menu Cierres Contables. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="fechas row">
            <div className="listnomi col-12">
              <select
                id="tipoCierre"
                name="tipoCierre"
                value={this.state.tipoCierre}
                onChange={this.onChange2.bind(this)}
              >
                <option> -- Tipo de Cierres --</option>
                <option value="1">Cierre Mensual.</option>
                <option value="2">Cierre Mensual por Cuenta.</option>
              </select>
            </div>
          </div>
          {this.state.tipoCierre !== "" && (
          <div className="row">
            <div className="fechas col-4">
              <select
                id="codigo3"
                name="codigo3"
                value={this.state.codigo3}
                onChange={this.onChange.bind(this)}
              >
                <option>--Mes--</option>
                {this.state.mes.map((item, i) => {
                  return (
                    <option key={item.variable1} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
              <select
                id="codigo4"
                name="codigo4"
                value={this.state.codigo4}
                onChange={this.onChange.bind(this)}
              >
                <option>--Año--</option>
                {this.state.anio.map((item, i) => {
                  return (
                    <option key={item.variable1} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.tipoCierre === "2" && (
              <div className="contenedorcedula1 col-6">
                <select
                  id="cuentaContable"
                  name="cuentaContable"
                  value={this.state.cuentaContable}
                  onChange={this.onChange.bind(this)}
                >
                  <option>Cuenta Contable</option>

                  {this.state.cuenta.map((item, i) => {
                    return (
                      <option key={i} value={item.variable2}>
                        {item.variable6 + " - " + item.variable3}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.guardar.bind(this)}>
                Agregar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
          )}

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc2">Mes y AÑO</th>
                    {this.state.tipoCierre === "2" && (
                      <th className="titucxc2">Cuenta</th>
                    )}
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.iconcepto.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx1">{item.variable2}</td>
                      {this.state.tipoCierre === "2" && (
                        <td className="totalescxcx1">{item.variable3}</td>
                      )}
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConceptoAction;
