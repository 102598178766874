import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
// import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./conciliacion.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaOn: false,
      mensajealerta: false,
      fecha: "",
      anoEscolar: "",
      fechaDesde: moment().format("YYYY-MM-DD"),
      fechaHasta: moment().format("YYYY-MM-DD"),
      montoB: 0,
      debe: "",
      haber: "",
      saldo: "",
      saldoMesAnterior: "",
      saldoMesAnteriorN: "",
      saldoMesD: "",
      saldoMesH: "",
      banco: "",
      itenConciliacion: [],
      opcedulaP: "",
      mes: [],
      anio: [],
      arreglo1: [],
      arreglo1: [],
      montosIni: [],
      codigo3: "",
      codigo4: "",
      codigo5: "",
      codigo6: "",
      loading: false,
      transitoMesD: "",
      transitoMesH: "",
      saldoIniOn: false,
      montoini: "",
      siDebito: "SI",
      siCredito: "SI",
    };
  }

  limpiar = () => {
    this.setState({
      montoB: 0,
      debe: "",
      haber: "",
      saldo: "",
      saldoMesAnterior: "",
      saldoMesAnteriorN: "",
      saldoMesD: "",
      saldoMesH: "",
      banco: "",
      mensajealerta: false,
      opcedulaP: "",
      codigo3: "",
      codigo4: "",
      codigo7: "",
      codigo8: "",
      loading: false,
      arreglo1: [],
      arreglo1: [],
      montosIni: [],
      transitoMesD: "",
      transitoMesH: "",
      saldoIniOn: false,
      montoini: "",
      siDebito: "SI",
      siCredito: "SI",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    console.log(e.target.name);
    if (e.target.name === "siDebito") {
      if (e.target.checked) {
        this.setState({
          siDebito: "SI",
        });
      } else {
        this.setState({
          siDebito: "NO",
        });
      }
    }
    if (e.target.name === "siCredito") {
      if (e.target.checked) {
        this.setState({
          siCredito: "SI",
        });
      } else {
        this.setState({
          siCredito: "NO",
        });
      }
    }
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "idmateria") this.consultarActividades(e.target.value);
  }
  onChange4 = (e, f, g, h, index) => {
    this.setState({
      loading: true,
    });
    let element = e.target;
    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      //console.log(e.target.name);
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("chequeo", e.target.value);
      data.append("destino", h);
      data.append("idconciliacion", e.target.name);
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("boton", "guardarChequeo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xdebito = parseFloat(f);
            let xcredito = parseFloat(g);
            let xpaso = 0;
            if (e.target.checked) {
              xpaso = 1;
              let sdebito = this.state.debe;
              let scredito = this.state.haber;
              let sinicial = this.state.montoB;
              let ssaldo = 0;
              sdebito = sdebito - xdebito;
              scredito = scredito - xcredito;
              ssaldo = sinicial + sdebito - scredito;
              console.log("prueba2");
              // console.log(index);
              console.log(e.target.checked);
              let nominaP_ = Array.from(this.state.itenConciliacion);
              nominaP_[index].variable7 = "";
              this.setState({
                itenConciliacion: nominaP_,
                debe: sdebito,
                haber: scredito,
                saldo: ssaldo,
              });
              // let nominaP_ = Array.from(this.state.itenConciliacion);
              // nominaP_[index].variable7 = "";
              // this.setState({itenConciliacion: nominaP_});
            }
            if (!e.target.checked) {
              if (xpaso === 0) {
                let sdebito = this.state.debe;
                let scredito = this.state.haber;
                let sinicial = this.state.montoB;
                let ssaldo = 0;
                sdebito = sdebito + xdebito;
                scredito = scredito + xcredito;
                ssaldo = sinicial + sdebito - scredito;
                console.log(e.target.checked);
                console.log("prueba3");
                let nominaP_ = Array.from(this.state.itenConciliacion);
                nominaP_[index].variable7 = "SI";
                this.setState({
                  itenConciliacion: nominaP_,
                  debe: sdebito,
                  haber: scredito,
                  saldo: ssaldo,
                });
              }
            }
            //this.consultar();
            this.setState({loading: false,});
          } else {
            this.setState({loading: false,});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  eliminar = () => {
    if (this.state.idactividad.length > 0) {
      let data = new FormData();

      data.append("idactividad", this.state.idactividad);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/actividades.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          let e = "";
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  chequeartodo() {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("siDebito", this.state.siDebito);
    data.append("siCredito", this.state.siCredito);
    data.append("boton", "chequeartodo");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/conciliacion.php",
      data: data,
    }).then((res) => {
      let xrutas = res.data.variable2;
      let xsaldo = parseFloat(
        parseFloat(this.state.montoB) + res.data.variable3 - res.data.variable4
      ).toFixed(2);
      if (res.data.variable1) {
        this.consultar();
      }
    });
  }

  quitarChequeos() {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("siDebito", this.state.siDebito);
    data.append("siCredito", this.state.siCredito);
    data.append("boton", "quitarChequeos");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/conciliacion.php",
      data: data,
    }).then((res) => {
      let xrutas = res.data.variable2;
      let xsaldo = parseFloat(
        parseFloat(this.state.montoB) + res.data.variable3 - res.data.variable4
      ).toFixed(2);
      if (res.data.variable1) {
        this.consultar();
      }
    });
  }

  consultar() {
    //console.log(this.state.montoB);
    this.setState({
      loading: true,
      itenConciliacion:[],
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("banco", this.state.banco);
    data.append("fechaDesde", this.state.fechaDesde);
    data.append("fechaHasta", this.state.fechaHasta);
    data.append("fechames", this.state.codigo3);
    data.append("fechaanio", this.state.codigo4);
    data.append("tipofecha", this.state.opcedulaP);
    data.append("siDebito", this.state.siDebito);
    data.append("siCredito", this.state.siCredito);
    data.append("boton", "buscarAsientosContables");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/conciliacion.php",
      data: data,
    }).then((res) => {
      let xrutas = res.data.variable2;
      // let xsaldo = parseFloat(
      //   parseFloat(this.state.montoB) + res.data.variable6 - res.data.variable5
      // ).toFixed(2);
      let xsaldo = parseFloat(
        parseFloat(res.data.variable16) +
          res.data.variable3 -
          res.data.variable4
      ).toFixed(2);
      //xsaldo = parseFloat(res.data.variable13).toFixed(2);
      let xsaldoI = parseFloat(
        parseFloat(xsaldo) + res.data.variable3 - res.data.variable4
      ).toFixed(2);
      let xsaldoY = parseFloat(
        res.data.variable10 - res.data.variable9
      ).toFixed(2);

      let cxsaldo = parseFloat(
        parseFloat(this.state.montoB) + res.data.variable13
      ).toFixed(2);

      // console.log(xsaldo);
      // console.log(xsaldoI);
      if (res.data.variable1) {
        this.setState({
          itenConciliacion: xrutas,
          debe: res.data.variable3,
          haber: res.data.variable4,
          saldo: xsaldo,
          montoB: res.data.variable16,
          saldoMesAnterior: xsaldoI,
          saldoMesAnteriorN: xsaldoY,
          saldoMesD: res.data.variable7,
          saldoMesH: res.data.variable8,
          arreglo1: res.data.variable14,
          arreglo2: res.data.variable15,
          transitoMesD: res.data.variable17,
          transitoMesH: res.data.variable18,
          loading: false,
        });
        //this.consultar2();
      } else {
        this.setState({
          itenConciliacion: xrutas,
          mensajealerta: true,
          tituloM: "Menu de Actividades",
          cuerpoM: "No se encontraron Asientos",
          loading: false,
        });
      }
    });
  }

  consultar2() {
    //console.log(this.state.montoB);
    let xmes = this.state.codigo3;
    xmes = xmes - 1;
    if (xmes > 2) {
      this.setState({
        loading: true,
      });
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("banco", this.state.banco);
      data.append("fechaDesde", this.state.fechaDesde);
      data.append("fechaHasta", this.state.fechaHasta);
      data.append("fechames", xmes);
      data.append("fechaanio", this.state.codigo4);
      data.append("tipofecha", this.state.opcedulaP);
      data.append("boton", "buscarAsientosContables");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/conciliacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          let xsaldo = parseFloat(
            res.data.variable6 - res.data.variable5
          ).toFixed(2);
          this.setState({
            transitoMesD: res.data.variable7,
            transitoMesH: res.data.variable8,
            loading: false,
          });
          this.informeConciliar(res.data.variable7, res.data.variable8, xsaldo);
        }
      });
    } else {
      this.informeConciliar("", "", "");
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  grabartexto() {
    if (
      this.state.descripcion.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.ocultar.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcion", this.state.descripcion);
      data.append("ocultar", this.state.ocultar);
      data.append("boton", "crearActividades");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Actividades",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    if (this.props.usuario1.nivel === "3") {
      form.append("boton", "buscarPersonal3");
    } else {
      form.append("boton", "buscarPersonal2");
    }
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;

      this.setState({ iprofesor: xiprofesor });
      //this.buscarCurso(item.variable1);
    });
  };

  imprimir = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", this.state.idcurso);
    data.append("idprofesor", this.state.idprofesor);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteAsignacionesCorregidas.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  imprimirSeleccionExcel = () => {
    let data = new FormData();
    data.append("siDebito", this.state.siDebito);
    data.append("siCredito", this.state.siCredito);
    data.append("arreglo", JSON.stringify(this.state.itenConciliacion));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteConciliacionExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Conciliacion.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  informeConciliar = () => {
    let data = new FormData();
    data.append("banco", this.state.banco);
    data.append("tipofecha", this.state.opcedulaP);
    data.append("saldoMesA", this.state.montoB);
    data.append("saldoMesN", this.state.saldoMesAnteriorN);
    data.append("debe", this.state.debe);
    data.append("haber", this.state.haber);
    data.append("saldo", this.state.saldo);
    //data.append("saldoMesAnterior", g);
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("saldoMesD", this.state.saldoMesD);
    data.append("saldoMesH", this.state.saldoMesH);
    data.append("transitoMesD", this.state.transitoMesD);
    data.append("transitoMesH", this.state.transitoMesH);
    data.append("arreglo1", JSON.stringify(this.state.arreglo1));
    data.append("arreglo2", JSON.stringify(this.state.arreglo2));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteConciliacionBancariaG.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          // dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  cargarSaldosIniciales = () => {
    let form = new FormData();
    form.append("banco", this.state.banco);
    form.append("boton", "cargarSaldosIniciales");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xmontosIni = res.data.variable2;

      this.setState({ montosIni: xmontosIni });
      //this.buscarCurso(item.variable1);
    });
  };

  saldoIni = () => {
    if (this.state.saldoIniOn) {
      this.setState({ saldoIniOn: false, montosIni: [] });
    } else {
      this.cargarSaldosIniciales();
      this.setState({ saldoIniOn: true });
    }
  };

  eliminarMontosI = (e) => {
    let form = new FormData();
    form.append("idsaldo", e);
    form.append("boton", "eliminarMontosI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.cargarSaldosIniciales();
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "El Registro se Elimino con Exito",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "Ocurrio un error, Verifique por Favor",
        });
      }
    });
  };

  guardarSaldosIniciales = () => {
    let form = new FormData();
    form.append("banco", this.state.banco);
    form.append("mes", this.state.codigo7);
    form.append("anio", this.state.codigo8);
    form.append("monto", this.state.montoini);
    form.append("boton", "guardarSaldosIniciales");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.cargarSaldosIniciales();
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "Se Guardo el Registro con Exito",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "Ocurrio un error, Verifique por Favor",
        });
      }
    });
  };

  componentDidMount() {
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE CONTABLE DE CONCILIACION BANCARIA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          {this.state.saldoIniOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  {/* <span className="titulocasos">
                    Listado de Notas de Credito Pendientes
                  </span> */}
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.saldoIni.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="form-group row">
                  <div className="contenedorcedula1f col-6">
                    <select
                      id="codigo7"
                      name="codigo7"
                      value={this.state.codigo7}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo8"
                      name="codigo8"
                      value={this.state.codigo8}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1 col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="montoini"
                      id="montoini"
                      autoComplete="off"
                      placeholder="Monto Mes Inicial"
                      maxLength="60"
                      onChange={this.onChange.bind(this)}
                      value={this.state.montoini}
                    />
                  </div>
                  <div className="contenedorcedula1 col-2">
                    <button
                      className="boton"
                      onClick={this.guardarSaldosIniciales.bind(this)}
                    >
                      Guardar Saldo
                    </button>
                  </div>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">CUENTA</th>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc1">MONTO</th>
                          <th className="titucxc1">
                            <FeatherIcon
                              icon="delete"
                              size="30px"
                              stroke="#999"
                              color="red"
                            ></FeatherIcon>
                          </th>
                        </tr>
                      </thead>
                      {this.state.montosIni.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd1"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="totalescxc" width="10">
                              {item.variable3}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable4}
                            </td>
                            <td className="renglon" width="10">
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.eliminarMontosI.bind(
                                  this,
                                  item.variable1
                                )}
                              ></FeatherIcon>

                              {item.variable7}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="botonesConc row">
            <div className="form-group col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="banco"
                  name="banco"
                  value={this.state.banco}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Banco --</option>
                  <option value="101002"> Banco BAC</option>
                  <option value="101003"> Banco BANESCO</option>
                  <option value="101005"> Banco GENERAL</option>
                </select>
              </div>
              <div className="contenedorcedula1f2 col-1">
                <div className="radio">
                  <label className="container1">
                    Debito:
                    <input
                      type="checkbox"
                      id="siDebito"
                      name="siDebito"
                      value="SI"
                      checked={this.state.siDebito === "SI"}
                      onChange={this.onChange1.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container1">
                    Credito:
                    <input
                      type="checkbox"
                      id="siCredito"
                      name="siCredito"
                      value="SI"
                      checked={this.state.siCredito === "SI"}
                      onChange={this.onChange1.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="contenedorcedula1f col-2">
                <div className="radio">
                  <label className="container">
                    Por Fechas
                    <input
                      type="radio"
                      value="PFECHAS"
                      name="opcedulaP"
                      checked={this.state.opcedulaP === "PFECHAS"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="radio">
                  <label className="container">
                    por Mes
                    <input
                      type="radio"
                      value="PMES"
                      name="opcedulaP"
                      checked={this.state.opcedulaP === "PMES"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {this.state.opcedulaP === "PFECHAS" && (
                <div className="contenedorcedula1f col-3">
                  <label>
                    Fecha Desdet
                    <input
                      className="form-control"
                      type="date"
                      name="fechaDesde"
                      id="fechaDesde"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fechaDesde}
                    />
                  </label>
                </div>
              )}
              {this.state.opcedulaP === "PFECHAS" && (
                <div className="contenedorcedula1f col-3">
                  <label>
                    Fecha Hasta
                    <input
                      className="form-control"
                      type="date"
                      name="fechaHasta"
                      id="fechaHasta"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fechaHasta}
                    />
                  </label>
                </div>
              )}
              {this.state.opcedulaP === "PMES" && (
                <div className="contenedorcedula1f col-5">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <button className="boton" onClick={this.consultar.bind(this)}>
                Consultar
              </button>
              <button
                className="boton"
                onClick={this.quitarChequeos.bind(this)}
              >
                Quitar Conciliación
              </button>
              <button className="boton" onClick={this.chequeartodo.bind(this)}>
                Conciliar Todos
              </button>
              {this.state.opcedulaP === "PMES" && (
                <button
                  className="boton"
                  onClick={this.informeConciliar.bind(this)}
                >
                  Informe Conciliar
                </button>
              )}
              <button
                className="boton"
                onClick={this.imprimirSeleccionExcel.bind(this)}
              >
                Imprimir Selección Excel
              </button>
            </div>
          </div>

          <div clasName="form-group row">
            <div className="cont-listmedi">
              <div className="listcxc col-12">
                <table className="tablacxct" border="1">
                  <thead
                    className={
                      this.state.saldoIniOn === true ? "" : "theadcreartabla"
                    }
                  >
                    <tr>
                      <th className="titucxc2">DOCUMENTO</th>
                      <th className="titucxc1">FECHA</th>
                      <th className="titucxc2">DESCRIPCION</th>
                      <th className="titucxc1">DEBITO</th>
                      <th className="titucxc1">CREDITO</th>
                      <th className="titucxc1">RESPONSABLE</th>
                      {this.state.opcedulaP === "PMES" && (
                        <th className="titucxc1">CONCILIAR</th>
                      )}
                    </tr>
                  </thead>
                  {this.state.itenConciliacion.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : "odd1"}
                        key={item.variable1}
                        id="tabla"
                      >
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "totalescxc"
                          }
                          width="10"
                        >
                          {item.variable2}
                        </td>
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "renglon"
                          }
                          width="10"
                        >
                          {item.variable3}
                        </td>
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "totalescxc1"
                          }
                          width="10"
                        >
                          {item.variable4}
                        </td>
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "totalescxc1"
                          }
                          width="10"
                        >
                          {item.variable5}
                        </td>
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "totalescxc1"
                          }
                          width="10"
                        >
                          {item.variable6}
                        </td>
                        <td
                          className={
                            item.variable9 === "IEF"
                              ? "verde"
                              : item.variable9 === "ICH"
                              ? "azul"
                              : item.variable9 === "ITV"
                              ? "morado"
                              : item.variable9 === "ITC"
                              ? "rojo"
                              : "totalescxc1"
                          }
                          width="10"
                        >
                          {item.variable8}
                        </td>
                        {this.state.opcedulaP === "PMES" && (
                          <td
                            className={
                              item.variable9 === "IEF"
                                ? "verde"
                                : item.variable9 === "ICH"
                                ? "azul"
                                : item.variable9 === "ITV"
                                ? "morado"
                                : item.variable9 === "ITC"
                                ? "rojo"
                                : "totalescxcx"
                            }
                            width="10"
                          >
                            <input
                              type="checkbox"
                              className="opvacunacionsi"
                              name={item.variable1}
                              id={item.variable1}
                              checked={item.variable7 === "SI"}
                              onChange={(e) => {
                                this.onChange4(
                                  e,
                                  item.variable5,
                                  item.variable6,
                                  item.variable9,
                                  i
                                );
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>

          <div className="botonesCon row">
            <div className="contenedorcedula2 col-9">
              {/* <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.state.guardarOn ? "" : this.guardarCxP}
              >
                Guardar
              </button> */}
              {/* <button className="boton" onClick={this.reporte}>
                Reporte
              </button>*/}
              {this.state.banco.length > 0 && (
                <button className="boton" onClick={this.saldoIni.bind(this)}>
                  Saldos Iniciales
                </button>
              )}
              <button className="boton" onClick={this.props.salir}>
                Salir
              </button>
            </div>
            {/* <div className="contenedorcedula2xc col-3">
              <span className="tituloconci col-12"></span>
              <span className="tituloconci col-12">DEBE</span>
              <span className="tituloconci col-12">HABER</span>
            </div> */}
            <div className="contenedorcedula2 col-3">
              <div className="flexContainer col-12">
                <div className="izquierda">
                  <span>Saldo I.</span>
                </div>
                <div className="derecha">
                  <input
                    className="form-control"
                    type="text"
                    name="montoB"
                    id="montoB"
                    autoComplete="off"
                    placeholder="Monto del Banco"
                    maxLength="10"
                    readOnly="readOnly"
                    onChange={this.onChange.bind(this)}
                    value={this.state.montoB}
                  />
                </div>
              </div>

              <div className="flexContainer col-12">
                <div className="izquierda">
                  <span>Debito</span>
                </div>
                <div className="derecha">
                  <input
                    className="form-control"
                    type="text"
                    name="debe"
                    id="debe"
                    autoComplete="off"
                    placeholder="DEBE"
                    maxLength="10"
                    readOnly="readOnly"
                    onChange={this.onChange.bind(this)}
                    value={this.state.debe}
                  />
                </div>
              </div>
              <div className="flexContainer col-12">
                <div className="izquierda">
                  <span>Credito</span>
                </div>
                <div className="derecha">
                  <input
                    className="form-control"
                    type="text"
                    name="haber"
                    id="haber"
                    autoComplete="off"
                    placeholder="HABER"
                    maxLength="10"
                    readOnly="readOnly"
                    onChange={this.onChange.bind(this)}
                    value={this.state.haber}
                  />
                </div>
              </div>
              <div className="flexContainer col-12">
                <div className="izquierda">
                  <span>Saldo</span>
                </div>
                <div className="derecha">
                  <input
                    className="form-control"
                    type="text"
                    name="saldo"
                    id="saldo"
                    autoComplete="off"
                    placeholder="SALDO"
                    maxLength="10"
                    readOnly="readOnly"
                    onChange={this.onChange.bind(this)}
                    value={this.state.saldo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
