import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./balanceG.css";
import FeatherIcon from "feather-icons-react";
import Alert from "../../modal/alert";

class Facturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      externoOn: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      nocre: [],
      nocre2: [],
      edogp: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      nrecibo: "",
      notascrediOn: false,
      consultaCompOn: false,
      totalDebito: 0,
      totalCredito: 0,
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      edogp: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      nrecibo: "",
      notascrediOn: false,
      consultaCompOn: false,
      totalDebito: 0,
      totalCredito: 0,
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
    });
  };

  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: false,
      });
    }
    if (!this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: true,
      });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }

  onChange4 = (e) => {
    // let element = e.target;
    // if (element.checked) {
    // console.log(e.target.value);
    // console.log(e.target.name);
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("idfacturaRe", e);
    data.append("boton", "guardarNCT");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({});
        }
      });
    //}
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  consulbuscarRecibotarFactura = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("nrecibo", this.state.nrecibo);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito.",
            cuerpoM: res.data.variable3,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("nrecibo", this.state.nrecibo);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) > 0
    ) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  guardarFactura1 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  notascrediOn = (e) => {
    if (this.state.notascrediOn) {
      this.setState({ notascrediOn: false });
    } else {
      this.buscarNC(e);
      //this.setState({ notascrediOn: true });
    }
  };

  consultaCompOn = (e, f) => {
    if (this.state.consultaCompOn) {
      this.setState({ consultaCompOn: false });
    } else {
      this.consultaComp(e, f);
      //this.setState({ notascrediOn: true });
    }
  };

  consultaComp = async (e, f) => {
    //console.log(e);
    if (e !== "") {
      this.setState({
        loading: true,
      });
      //console.log(e);
      let data = new FormData();
      data.append("idcomprobante", e);
      data.append("tipoAsiento", f);
      data.append("boton", "consultarComprobante");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/balanceGeneral.php";
      let res = await axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.consultarProveedores()
            let xfacturasP = res.data.variable2;
            this.setState({
              nocre2: xfacturasP,
              consultaCompOn: true,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              mensajealerta: true,
              tituloM: "Menu Busqueda de Registros Contables",
              cuerpoM: "No Existen Registro, gracias...",
            });
          }
        });
      this.setState({
        //loading:false,
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda Registros Contables",
        cuerpoM:
          "Debe Seleccionar una Cuenta de Registro y No Una Cuenta de Mayor, gracias...",
      });
    }
  };

  buscarNC = async (e) => {
    //console.log(e);
    if (e !== "") {
      this.setState({
        loading: true,
      });
      //console.log(e);
      let data = new FormData();
      data.append("cuenta", e);
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("boton", "consultarRegistroGP");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/balanceGeneral.php";
      let res = await axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.consultarProveedores()
            let xfacturasP = res.data.variable2;
            this.setState({
              nocre: xfacturasP,
              totalDebito: res.data.variable3,
              totalCredito: res.data.variable4,
              notascrediOn: true,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              mensajealerta: true,
              tituloM: "Menu Busqueda de Registros Contables",
              cuerpoM: "No Existen Registro, gracias...",
            });
          }
        });
      this.setState({
        //loading:false,
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda Registros Contables",
        cuerpoM:
          "Debe Seleccionar una Cuenta de Registro y No Una Cuenta de Mayor, gracias...",
      });
    }
  };

  consultarFacturaRe = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("ruc", this.state.ruc);
    data.append("boton", "consultarFacturaGeneralPendiente");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasCRe: xfacturasP,
            factuPendientesOn: true,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturasIRe = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  limpiarNC = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "limpiarNC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {});
  };

  balanceG = async () => {
    //console.log(this.state.opcedulaP)
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("boton", "consultarBG");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/balanceGeneral.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xedogp = res.data.variable2;
          //this.limpiar();
          this.setState({
            edogp: xedogp,
            loading: false,
          });
        }
      });
  };

  componentDidMount() {
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          {/* <div className="titulo-cxc ">
            <p>NOTA DE CREDITO. </p>
          </div> */}
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="fechas col-12">
            <select
              id="codigo3"
              name="codigo3"
              value={this.state.codigo3}
              onChange={this.onChange.bind(this)}
            >
              <option>--Mes--</option>
              {this.state.mes.map((item, i) => {
                return (
                  <option key={item.variable1} value={item.variable1}>
                    {item.variable2}
                  </option>
                );
              })}
            </select>
            <select
              id="codigo4"
              name="codigo4"
              value={this.state.codigo4}
              onChange={this.onChange.bind(this)}
            >
              <option>--Año--</option>
              {this.state.anio.map((item, i) => {
                return (
                  <option key={item.variable1} value={item.variable1}>
                    {item.variable1}
                  </option>
                );
              })}
            </select>

            <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.balanceG}
            >
              Consultar
            </button>
            {/* <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.notascrediOn}
            >
              Notas/Credito Pendientes
            </button> */}
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>

          {this.state.notascrediOn === true && (
            <div className="flotador4xfbg">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  {/* <span className="titulocasos">
                    Listado de Notas de Credito Pendientes
                  </span> */}
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.notascrediOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">CODIGO</th>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc2">DESCRIPCION</th>
                          <th className="titucxc1">DEBITO</th>
                          <th className="titucxc1">CREDITO</th>
                        </tr>
                      </thead>
                      {this.state.nocre.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="totalescxc"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable3}
                            </td>
                            <td
                              className="totalescxc"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable4}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable5}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable6}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable7}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className="col-6"></div>
                  <div className="totalBg col-3">
                    <span className="tituloBg">{this.state.totalDebito}</span>
                  </div>
                  <div className="totalBg col-3">
                    <span className="tituloBg">{this.state.totalCredito}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.consultaCompOn === true && (
            <div className="flotador4xfbg">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  {/* <span className="titulocasos">
                    Listado de Notas de Credito Pendientes
                  </span> */}
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.consultaCompOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">CODIGO</th>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc2">DESCRIPCION</th>
                          <th className="titucxc1">DEBITO</th>
                          <th className="titucxc1">CREDITO</th>
                        </tr>
                      </thead>
                      {this.state.nocre2.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="totalescxc"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable3}
                            </td>
                            <td
                              className="totalescxc"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable4}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable5}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable6}
                            </td>
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.consultaCompOn.bind(
                                this,
                                item.variable1,
                                item.variable2
                              )}
                            >
                              {item.variable7}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className="col-6"></div>
                  <div className="totalBg col-3">
                    <span className="tituloBg">{this.state.totalDebito}</span>
                  </div>
                  <div className="totalBg col-3">
                    <span className="tituloBg">{this.state.totalCredito}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="cont-listmedi row">
            <div className="listcxcGP col-12">
              <table className="tablacxc" border="1">
                {/* <thead>
                  <tr>
                    <th className="titucxc2">PRODUCTO</th>
                    <th className="titucxc1">CANTIDAD</th>
                    <th className="titucxc1">P/UNITARIO</th>
                    <th className="titucxc1">ITBMS</th>
                    <th className="titucxc1">TOTAL</th>
                    <th className="titucxc1">N/CREDITO</th>
                  </tr>
                </thead> */}
                {this.state.edogp.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className={
                          item.variable2 === "1"
                            ? "totalescxcgp"
                            : "totalescxc1gp"
                        }
                        width="7"
                        onClick={this.notascrediOn.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td className="totalescxc2gp" width="10">
                        {item.variable4}
                      </td>
                      <td
                        className={
                          item.variable4 === ""
                            ? "totalescxc3gp"
                            : "totalescxc4gp"
                        }
                        width="10"
                        onClick={this.notascrediOn.bind(this, item.variable1)}
                      >
                        {item.variable5}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Facturacion);
