import React, { Component } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
//import axios from "axios";
import Alert from "../../modal/alert";
//import {API, API_PRINT} from './main'
//import AdjuntarEvaluacion from "../subirPublicarNotas/subirPublicarNotas";

//componentes

function ImprimirE(props) {
  const [mensajealerta, setMensajealerta] = React.useState(false);
  const [tituloM, setTituloM] = React.useState("");
  const [cuerpoM, setCuerpoM] = React.useState("");
  
  const EnviarMensaje = async () => {
    
  };
  const InitData = async () => {  
  };

  const handleClose = () => {
    setMensajealerta(false);
  };

  React.useEffect(() => {
    // InitData();
  }, []);

  return (
      
    <div>
      <Container>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )} 
        <Row>
          <Col>
            <Send onClick={props.imprimirExcel}>
              <FeatherIcon icon="trello" stroke="#fff" />
            </Send>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(ImprimirE);

const Container = styled.div`
  height: 70vh;
`;

const Send = styled.button`

  margin-top: 20px;
  margin-right: 40px;
  border-radius: 3px;
  width: 80%;
  height: 80%;
  border: solid 1px #eee;
  background-color: #028d7b;
  text-align: center;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  svg {
    width: 20px;
    stroke: #ffff;
  }
`;
