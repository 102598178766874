import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./reporte.css";
import Alert from "../../modal/alert";
import Conciliacion from "../conciliacion/conciliacion";
import CuentaI from "../reporteCuentaIndividual/reporteCuentaI";

class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      ianoEscolar: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      trimestre: "",
      mesVeranito: "",
      iprofesor: [],
      iprofesor2: [],
      icursos: [],
      verplanillaOn: false,
      conciliacionOn: false,
      cuentaIndividualOn: false,
      nalumno: [],
      loading: false,
      identificacion1: "",
      cursos: "",
      tipoR: "",
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "trimestre") this.buscarProfesor2(e.target.value);
  }

  onChange4 = (e) => {
    let element = e.target;
    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      //console.log(e.target.name);
      let data = new FormData();
      data.append("chequeo", e.target.value);
      data.append("recibo", e.target.name);
      data.append("boton", "guardarChequeo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/recibosxMes.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.ventasxMesTabla();
          } else {
            this.setState({});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      icursos: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      verplanillaOn: false,
      conciliacionOn: false,
      nalumno: [],
      idprofesor: "",
      iprofesor: [],
      iprofesor2: [],
      trimestre: "",
      mesVeranito: "",
      loading: false,
      identificacion1: "",
      tipoR: "",
    });
  };

  buscarTipoReporte = () => {
    if (this.state.tbusqueda === "1") this.conciliacionC();
    if (this.state.tbusqueda === "2") this.gananciayPerdida();
    if (this.state.tbusqueda === "3") this.cuentaIndividualC();
    if (this.state.tbusqueda === "4") this.balanceGeneral();
    if (this.state.tbusqueda === "5") this.informeGastoFacturacion();
    if (this.state.tbusqueda === "6") this.canjesMes();
    if (this.state.tbusqueda === "7") this.veranitoXEdad();
    if (this.state.tbusqueda === "8") this.gradoSeccion();
    if (this.state.tbusqueda === "9") this.gradosinSeccion();
    if (this.state.tbusqueda === "10") this.contactoRepresentante();
    if (this.state.tbusqueda === "11") this.datosMeduca();
    if (this.state.tbusqueda === "12") this.reporteMensualidad();
    if (this.state.tbusqueda === "13") this.reporteConsultaHabilitar();
    if (this.state.tbusqueda === "14") this.asignacionesEvaluadas();
    if (this.state.tbusqueda === "15") this.contactoRepresentante();
    if (this.state.tbusqueda === "16") this.gradoSeccionFN();
    if (this.state.tbusqueda === "17") this.imprimirBoletin();
    if (this.state.tbusqueda === "18") this.reporteBecados();
    if (this.state.tbusqueda === "19") this.reporteMensualPendiente();
    if (this.state.tbusqueda === "20") this.reporteIngresos();
    if (this.state.tbusqueda === "21") this.reporteContratos();
    if (this.state.tbusqueda === "22") this.reporteReIn();
    if (this.state.tbusqueda === "23") this.contactoRepresentanteCorreo();
    if (this.state.tbusqueda === "24") this.veranitoXEdadExcel();
    if (this.state.tbusqueda === "25") this.reporteVeranitoCXC();
    if (this.state.tbusqueda === "26") this.contactoRepresentanteCorreoExcel();
    if (this.state.tbusqueda === "27") this.reporteGradoSeccionExcel();
    if (this.state.tbusqueda === "28") this.contactoRepresentanteCorreoExcel2();
    if (this.state.tbusqueda === "29") this.gradoEdad();
    if (this.state.tbusqueda === "30") this.alumnosxSeccionMensualidad();
    if (this.state.tbusqueda === "31") this.boletinAnoAnterior();
    if (this.state.tbusqueda === "32") this.reporteFAnoAnterior();
    if (this.state.tbusqueda === "33") this.alumnosxSeccionHorario();
    if (this.state.tbusqueda === "34") this.alumnosxSeccionMensualidadExcel();
    if (this.state.tbusqueda === "35") this.gradoSeccionHorario();
    if (this.state.tbusqueda === "36") this.reporteSeccionSexo();
    if (this.state.tbusqueda === "37") this.reporteLibroV();
    if (this.state.tbusqueda === "38") this.reporteLibroC();
  };

  reporteLibroC = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/libroCompras.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteLibroV = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/libroVentas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  reporteSeccionSexo = () => {
    if (this.state.anoEscolar.length > 0 && this.state.tipoR.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("tipoR", this.state.tipoR);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteAlumnosSeccionSexo.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año y elegir un tipo de reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteReIn = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/ReporteInscritoReinscrito.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteContratos = () => {
    if (
      this.state.codigo3.length > 0 &&
      // this.state.anoEscolar.length > 0 &&
      this.state.codigo4.length > 0
    ) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      //data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteContrato.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteIngresos = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      //this.setState({ loading: true });
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anoEscolar", this.state.codigo4);
      data.append("dia", this.state.codigo1);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteIngresosTipos.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        //this.limpiar2()
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteMensualPendiente = () => {
    if (
      this.state.codigo3.length > 0 &&
      // this.state.anoEscolar.length > 0 &&
      this.state.codigo4.length > 0
    ) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      //data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteCobroMensual.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  conciliacionC = () => {
    if (this.state.conciliacionOn) {
      this.setState({ conciliacionOn: false });
    } else {
      this.setState({ conciliacionOn: true });
    }
  };

  cuentaIndividualC = () => {
    if (this.state.cuentaIndividualOn) {
      this.setState({ cuentaIndividualOn: false });
    } else {
      this.setState({ cuentaIndividualOn: true });
    }
  };

  reporteMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteMontoMensualidad.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  informeGastoFacturacion= () => {
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/informeGastoFacturacion.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };


  balanceGeneral = () => {
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteBalanceGeneral.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gananciayPerdida = () => {
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteGananciayPerdida.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  cerrarRecibo = () => {
    if (this.state.verplanillaOn) {
      this.setState({ verplanillaOn: false });
    } else {
      this.setState({ verplanillaOn: true });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  componentDidMount() {
    this.cargarFechas();
    this.buscarAnioEscolar();
  }

  ax = () => {};
  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          {/* <div className="titulo-pago ">
            <p>MENU PARA GENERAR REPORTES CONTABLES. </p>
          </div> */}
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.conciliacionOn && (
            <div className="flotador1xxC">
              <div className="flotador1">
                <Conciliacion
                  salir={() => {
                    this.setState({ conciliacionOn: false });
                  }}
                />
              </div>
            </div>
          )}
          {this.state.cuentaIndividualOn && (
            <div className="flotador1xxC">
              <div className="flotador1">
                <CuentaI
                  salir={() => {
                    this.setState({ cuentaIndividualOn: false });
                  }}
                />
              </div>
            </div>
          )}

          {!this.state.tokenVerificarOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>SELECCIONE EL TIPO DE REPORTE A EJECUTAR. </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar Reporte por --</option>
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="1">Reporte Conciliacion Bancaria</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="2">Ganancias y Perdidas</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="3">Reporte Por Cuenta Individual</option>
                    ) : (
                      ""
                    )}

                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="4">Reporte Balance General</option>
                    ) : (
                      ""
                    )}

                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="5">Informe Gasto x Facturación</option>
                    ) : (
                      ""
                    )}
                    {/*
                    <option value="20">Reporte Ingresos por Item</option>

                    <option value="5">Reporte Alumnos por Salón Horario</option>
                    <option value="35">Reporte por Horario</option>
                    <option value="36">Reporte por Seccion y Sexo</option>
                   
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="6">Reporte de Canjes en el Mes</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel === "2" ? (
                      <option value="29">
                        Reporte Por Grado Por Edad Excel
                      </option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="7">
                        Reporte de Veranito por Mes y Edad
                      </option>
                    )}
                    {this.props.usuario1.nivel !== "5" ? (
                      <option value="25">
                        Reporte Cuentas por Cobrar Veranito
                      </option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="24">
                        Reporte Excel de Veranito por Mes y Edad
                      </option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="8">Reporte por Grado y Sección</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="27">
                        Reporte por Grado y Seccion Excel
                      </option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="9">Reporte Alumnos sin Sección</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="16">
                        Reporte Grado/Sección/F. nacimiento
                      </option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="10">Reporte Contacto Representante</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="23">Reporte Correos Representante</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="26">
                        Correos Representante Excel por Grado y Seccion
                      </option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="28">
                        Correos Representante Excel por Grado
                      </option>
                    )}
                    <option value="21">
                      Reporte Maternales Matriculas Vencidas
                    </option>
                    <option value="22">Reporte Reinscrito e Incritos</option>
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="15">Reporte Contacto Madres</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="11">Reporte Datos Meduca</option>
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="30">
                        Reporte de Mensualidad Administrativo{" "}
                      </option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="34">
                        Reporte de Mensualidad Excel Administrativo{" "}
                      </option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "5" ? (
                      <option value="12">
                        Reporte de Mensualidad Academico
                      </option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel === "2" ? (
                      <option value="13">Planificación por Habilitar</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel === "2" ? (
                      <option value="14">Asignaciones Evaluadas.</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel === "2" && (
                      <option value="34">Reporte de Maestros</option>
                    )}
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="17">Boletines</option>
                    )}
                    <option value="31">Boletines Años Anteriores</option>
                    <option value="32">Reporte F Años Anteriores</option>
                    {this.props.usuario1.nivel !== "1" && (
                      <option value="18">Reporte Becarios</option>
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="19">Reporte Mensualidad por Cobrar</option>
                    ) : (
                      ""
                    )}*/}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="37">Libro de Ventas</option>
                    ) : (
                      ""
                    )}
                    {this.props.usuario1.nivel !== "2" &&
                    this.props.usuario1.nivel !== "5" ? (
                      <option value="38">Libro de Compras</option>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "2" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "5" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "38" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "37" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "20" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo1"
                      name="codigo1"
                      value={this.state.codigo1}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Dia--</option>
                      {this.state.dia.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>

                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "18" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "19" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "22" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "24" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {/* {this.state.tbusqueda === "1" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )} */}

              {/* {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )} */}

              {this.state.tbusqueda === "4" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {/* {this.state.tbusqueda === "5" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )} */}

              {this.state.tbusqueda === "6" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "7" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "25" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "8" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "9" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "10" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "11" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "12" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "15" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "16" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "17" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-3">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- TRIMESTRE --</option>
                      <option value="1">1er. Trimestre</option>
                      <option value="2">2do. Trimestre</option>
                      <option value="3">3er. Trimestre</option>
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-6">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">Seleccione Profesor</option>
                      {this.state.iprofesor2.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {/* {this.state.tbusqueda === "19" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )} */}

              {this.state.tbusqueda === "21" && (
                <div className="row">
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "23" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "26" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "27" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "28" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "29" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "30" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR--</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "31" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>

                    <input
                      className="form-control"
                      type="text"
                      name="identificacion1"
                      id="identificacion1"
                      autoComplete="off"
                      maxLength="20"
                      required
                      placeholder="Identificacion:"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacion1}
                    />
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "32" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="identificacion1"
                      id="identificacion1"
                      autoComplete="off"
                      maxLength="20"
                      required
                      placeholder="Identificacion:"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacion1}
                    />
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "33" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "34" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "35" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3"></div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="cursos"
                      name="cursos"
                      value={this.state.cursos}
                      onChange={this.onChange4.bind(this)}
                    >
                      <option>--Cursos/Horarios--</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "36" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3"></div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="tipoR"
                      name="tipoR"
                      value={this.state.tipoR}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Tipo de Reporte--</option>
                      <option value="1">MATERNAL</option>
                      <option value="2">PRIMARIA</option>
                    </select>
                  </div>
                </div>
              )}

              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarTipoReporte.bind(this)}
                >
                  Consultar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Reporte);
