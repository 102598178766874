import React, { Component } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import axios from "axios";
import Alert from "../../modal/alert";
import BotonGuardar from "../botones/guardar";
import BotonEliminar from "../botones/eliminar";
import BotonSalir from "../botones/salir";
import BotonImprimir from "../botones/imprimir";
import BotonImprimirExcel from "../botones/imprimirExcel";
import ConsultarCC from "../consultarCodigoContable/index";
import {
  CreateCodigoContable,
  DeleteCodigoContable,
} from "../../API/Contabilidad";
import {
  ListCodigoContablePHP,
  ListCodigoContableExcel,
} from "../../API/Reportes";
//componentes

function LibroCompras(props) {
  const [codigoContable, setCodigoContable] = React.useState("");
  const [nombreCodigo, setNombreCodigo] = React.useState("");
  const [idCodigoContable, setIdCodigoContable] = React.useState("");
  const [valorIndexar, setValorIndexar] = React.useState(false);
  const [subdivide, setSubdivide] = React.useState("");
  const [mensajealerta, setMensajealerta] = React.useState(false);
  const [tituloM, setTituloM] = React.useState("");
  const [cuerpoM, setCuerpoM] = React.useState("");

  const InitData = async () => {
    let form = new FormData();
    form.append("idarchivos", props.idarchivos);
    form.append("boton", "consultarPublicarNotas");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setCodigoContable(res.data.variable5);
      }
    });
  };

  const guardarCodigo = async () => {
    if(codigoContable.length===1)setSubdivide("SI") 
    if(codigoContable.length===3)setSubdivide("SI") 
    if(codigoContable.length===9)setSubdivide("NO") 

    if (
      codigoContable.length > 0 &&
      nombreCodigo.length > 0 &&
      subdivide.length > 0
    ) {
      //console.log("paso");
      let form = new FormData();
      form.append("idcodigoContable", idCodigoContable);
      form.append("codigoContable", codigoContable);
      form.append("nombreCodigo", nombreCodigo);
      form.append("subdivide", subdivide);
      form.append("boton", "crearCodigoContable");
      let res = await CreateCodigoContable(form);
      //console.log(res);
      if (res) {
        if (res.data.variable1) {
          setMensajealerta(true);
          setTituloM("Menu Crear Codigos Contables");
          setCuerpoM(res.data.variable2);
          if (valorIndexar) {
            setValorIndexar(false);
          } else {
            setValorIndexar(true);
          }
        } else {
          setMensajealerta(true);
          setTituloM("Menu Crear Codigos Contables");
          setCuerpoM(res.data.variable2);
        }
      }
    } else {
      setMensajealerta(true);
      setTituloM("Menu Crear Codigos Contables");
      setCuerpoM(
        "Debe haber un Codigo contable, Nombre de la Cuenta y la Sub-Division"
      );
    }
  };

  const eliminarCodigo = async () => {
    //console.log("paso");
    let form = new FormData();
    form.append("idcodigoContable", idCodigoContable);
    form.append("boton", "EliminarCodigoContable");
    let res = await DeleteCodigoContable(form);
    //console.log(res);
    if (res) {
      if (res.data.variable1) {
        setMensajealerta(true);
        setTituloM("Menu Crear Codigos Contables");
        setCuerpoM(res.data.variable2);
        if (valorIndexar) {
          setValorIndexar(false);
        } else {
          setValorIndexar(true);
        }
      } else {
        setMensajealerta(true);
        setTituloM("Menu Crear Codigos Contables");
        setCuerpoM(res.data.variable2);
      }
    }
  };
  const imprimirCodigo = () => {
    let data = new FormData();
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/listaCodigoContablePHP.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });

    
      // let form = new FormData();
      // let res = ListCodigoContablePHP(form);
      // if (res) {
      //   let blob = new Blob([res.data], { type: "application/pdf" });
      //   var fileURL = URL.createObjectURL(blob);
      //   window.open(fileURL);
      // }
  };
  const imprimirCodigoExcel = () => {
    let data = new FormData();
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/listaCodigoContableExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Codigoscontables.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
    
      // let form = new FormData();
      // let res = ListCodigoContablePHP(form);
      // if (res) {
      //   let blob = new Blob([res.data], { type: "application/pdf" });
      //   var fileURL = URL.createObjectURL(blob);
      //   window.open(fileURL);
      // }
  };

  const obterValorTabla = (xid, xcodigoC, xnombreC, xsubdivide) => {
    setIdCodigoContable(xid);
    setCodigoContable(xcodigoC);
    setNombreCodigo(xnombreC);
    setSubdivide(xsubdivide);
  };

  const handleClose = () => {
    setMensajealerta(false);
  };

  React.useEffect(() => {
    // InitData();
  }, []);

  return (
    <div>
      <Container>
        <ContainerTitulo>
          <p>CODIGOS CONTABLES. </p>
        </ContainerTitulo>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        <Row>
          <Col height="50px">
            <InputCodigo
              className="form-control"
              id="codigoContable"
              name="codigoContable"
              placeholder="Codigo Contable a Crear"
              autoComplete="off"
              value={codigoContable.toUpperCase()}
              onChange={(e) => setCodigoContable(e.target.value)}
            />
          </Col>
          <Col>
            <InputNombreCodigo
              className="form-control"
              id="nombreCodigo"
              name="nombreCodigo"
              placeholder="Nombre de la Cuenta"
              autoComplete="off"
              value={nombreCodigo.toUpperCase()}
              onChange={(e) => setNombreCodigo(e.target.value)}
            />
          </Col>
          {codigoContable.length === 6 && (
            <Col>
              <SelectSubdivide
                className=""
                id="subdivide"
                name="subdivide"
                value={subdivide}
                onChange={(e) => setSubdivide(e.target.value)}
              >
                <option>Se Subdivide</option>
                <option value="1">SI</option>
                <option value="2">NO</option>
              </SelectSubdivide>
            </Col>
          )}
          <Col>
            <ContainerBoton>
              <BotonGuardar guardar={guardarCodigo} />
              <BotonEliminar eliminar={eliminarCodigo} />
              <BotonImprimir imprimir={imprimirCodigo} />
              <BotonImprimirExcel imprimirExcel={imprimirCodigoExcel} />
              <BotonSalir />
            </ContainerBoton>
          </Col>
        </Row>
        <Row>
          <Cola>
            <ConsultarCC obtener={obterValorTabla} indexar={valorIndexar} />
          </Cola>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(LibroCompras);

const Container = styled.div`
  height: 80vh;
`;
const ContainerTitulo = styled.div`
  line-height: 30px;
  background-color: #028d7b;
  font-size: 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
`;
const ContainerBoton = styled.div`
  width: 150px;
  display: inline-flex;
  height: 20vh;
`;
const DataPonderacion = styled.input`
  height: 20px;
`;
const DataNota = styled.input`
  height: 20px;
`;
const SelectSubdivide = styled.select`
  margin-top: 25px;
  height: 25px;
`;
const InputCodigo = styled.input`
  margin-top: 20px;
  width: 300px;
`;
const InputNombreCodigo = styled.input`
  margin-top: 20px;
  width: 350px;
`;
const Cola = styled.div`
  height: 10vh;
`;
