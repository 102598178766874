//dependencias
import React from "react";
import { Route, Switch } from "react-router-dom";

//componentes
// import PlanEvaluacionM from "./components/planEvaluacionMaestro";
// import AutorizarCT from "./components/autorizarCopiadeTrimestre";
// import MaestroUsuario from "./components/maestroUsuarios";
// import RegistrarPagos from "./components/registroCompras";
// import CierreTrimestre from "./components/cierreTrimestral";
// import AutorizarExpediente from "./components/autorizarExpedientes";
// import AutorizarBoletines from "./components/autorizarBoletin";
// import HabitosActitudes from "./components/habitoActitudes";
// import ProfesorGuia from "./components/profesorGuia";
// import ConsultaEvaluaciones from "./components/consultaEvaluaciones";
// import CambioProfesor from "./components/cambioProfesores";
// import EvaluacioneT from "./components/evaluacionesTotales";
// import ExpedienteNotas from "./components/expedienteNotas";
// import ExpedienteMaterias from "./components/expedienteMaterias";
// import ReporteMaestros from "./components/reporteMaestros";
// import ArchivosPlanificacion from "./components/archivosPlani";
// import ConsultaActividades from "./components/consultaActividadesC";
// import ControlAsistencia from "./components/controlAsistencia";
// import MostrarTrabajos from "./components/mostrarTrabajosAlumnos";
// import Actividades from "./components/actividades";
// import ReportePM from "./components/reportePlaniMaestro";
// import AlumnosMaestro from "./components/alumnosXMaestro";
// import FechaPlanificacion from "./components/fechaPlanificacion";
// import ReporteP from "./components/reportePlanificacion";
// import PlanificacionAprobados from "./components/planificacionAprobados";
// import Planificacion from "./components/planificacion";
// import DescripcionE from "./components/descripcionEvaluacion";
// import SeccionesA from "./components/seccionAlumnos";
// import SeccionesP from "./components/seccionProfesor";
// import Secciones from "./components/secciones";
// import Evaluacion from "./components/evaluaciones";
// import PlanE from "./components/planEvaluacion";
// import MateriasP from "./components/materiaProfesor";
// import MateriasA from "./components/materiasAsignadas";
// import Materias from "./components/materias";
// import ReciboIndividual from "./components/reciboI";
// import Nomina from "./components/nomina";
// import Facturacion from "./components/facturacion";
// import CxP from "./components/cuentasxpagar";
// import Proveedores from "./components/proveedores";
// import Clientes from "./components/clientes";
// import CorreosIC from "./components/correosiC";
// import CorreosI from "./components/correosInternos";
// import Inventario from "./components/inventario";
// import Correos from "./components/correos";
// import Reportes from "./components/reportes";
// import ReporteInvFac from "./components/reporteInvFac";
// import Reinscripcion from "./components/reinscripcion";
// import ActivarEscolar from "./components/anioescolar";
// import Canje from "./components/canje";
// import Conceptos from "./components/conceptos";
// import Mensualidad from "./components/mensualidadGeneral";
// import Inscripcion from "./components/inscripcionG";
// import ArchivosUp from "./components/subirarchivos";
// import PlanillaI from "./components/planillaInscripcion";
// import CxC from "./components/cpc";
// import CxCCli from "./components/cxcCli";
import BalanceG from "./components/balanceGeneral";
import GananciaPerdida from "./components/consultaGyP";
import CierreMensualContable from "./components/cierreContable";
import ReportesContables from "./components/reporteContabilidad";
import ReferenciaC from "./components/referenciasC";
import Comprobante from "./components/comprobante/";
import LibroCuentas from "./components/libroCuentas";
import Configuracion from "./components/configuracion/";
import Inicio from "./components/inicio";
import Login from "./components/login";
import Pagina404 from "./components/pagina404";
//import Chat from "./components/Chat";

const AppRoutes = () => (
  <Switch>
{/*     
    <Route exact path="/planEvaluacionM" component={PlanEvaluacionM} />
    <Route exact path="/autorizarCT" component={AutorizarCT} />
    <Route exact path="/usuarios" component={MaestroUsuario} />
    <Route exact path="/registroCompras" component={RegistrarPagos} />
    <Route exact path="/cierreT" component={CierreTrimestre} />
    <Route exact path="/autorizarBoletines" component={AutorizarBoletines} />
    <Route exact path="/autorizarExpediente" component={AutorizarExpediente} />
    <Route exact path="/habitos" component={HabitosActitudes} />
    <Route exact path="/profesorGuia" component={ProfesorGuia} />
    <Route exact path="/consultaEvaluaciones" component={ConsultaEvaluaciones} />
    <Route exact path="/cambioProfesor" component={CambioProfesor} />
    <Route exact path="/evaluacionesT" component={EvaluacioneT} />
    <Route exact path="/expedienteNotas" component={ExpedienteNotas} />
    <Route exact path="/expedienteMaterias" component={ExpedienteMaterias} />
    <Route exact path="/reporteMaestros" component={ReporteMaestros} />
    <Route exact path="/archivosPlani" component={ArchivosPlanificacion} />
    <Route exact path="/consultaActividades" component={ConsultaActividades} />
    <Route exact path="/asistencia" component={ControlAsistencia} />
    <Route exact path="/actividadesA" component={MostrarTrabajos} />
    <Route exact path="/actividades" component={Actividades} />
    <Route exact path="/reportePlaniMaestro" component={ReportePM} />
    <Route exact path="/alumnosxMaestros" component={AlumnosMaestro} />
    <Route exact path="/reporteP" component={ReporteP} />
    <Route exact path="/fechaPlanificacion" component={FechaPlanificacion} />
    <Route
      exact
      path="/planificacionAprobados"
      component={PlanificacionAprobados}
    />
    <Route exact path="/planificacion" component={Planificacion} />
    <Route exact path="/descripcionE" component={DescripcionE} />
    <Route exact path="/seccionAlumnos" component={SeccionesA} />
    <Route exact path="/seccionProfesor" component={SeccionesP} />
    <Route exact path="/secciones" component={Secciones} />
    <Route exact path="/evaluaciones" component={Evaluacion} />
    <Route exact path="/planEvaluacion" component={PlanE} />
    <Route exact path="/materiaProfesor" component={MateriasP} />
    <Route exact path="/materiasAsignadas" component={MateriasA} />
    <Route exact path="/materias" component={Materias} />
    <Route exact path="/reciboI" component={ReciboIndividual} />
    <Route exact path="/nomina" component={Nomina} />
    <Route exact path="/facturacion" component={Facturacion} />
    <Route exact path="/cxp" component={CxP} />
    <Route exact path="/proveedores" component={Proveedores} />
    <Route exact path="/clientes" component={Clientes} />
    <Route exact path="/correosi" component={CorreosI} />
    <Route exact path="/correosiC" component={CorreosIC} />
    <Route exact path="/inventario" component={Inventario} />
    <Route exact path="/correos" component={Correos} />
    <Route exact path="/reporteInvFac" component={ReporteInvFac} />
    <Route exact path="/reportes" component={Reportes} />
    <Route exact path="/reinscripcion" component={Reinscripcion} />
    <Route exact strict path="/anioescolar" component={ActivarEscolar} />
    <Route exact strict path="/canje" component={Canje} />
    <Route exact strict path="/conceptos" component={Conceptos} />
    <Route exact strict path="/mensualidad" component={Mensualidad} />
    <Route exact strict path="/inscripcionG" component={Inscripcion} />
    <Route exact strict path="/adjuntos" component={ArchivosUp} />
    <Route exact strict path="/planillai" component={PlanillaI} />
    <Route exact strict path="/cxc" component={CxC} />*/}
    <Route exact strict path="/balanceG" component={BalanceG} />
    <Route exact strict path="/gananciaP" component={GananciaPerdida} />
    <Route exact strict path="/cierreMC" component={CierreMensualContable} />
    <Route exact path="/reporteContabilidad" component={ReportesContables} />
    <Route exact strict path="/referenciaC" component={ReferenciaC} />
    <Route exact strict path="/comprobante" component={Comprobante} />
    <Route exact strict path="/libroCuentas" component={LibroCuentas} />
    <Route exact strict path="/configuracion" component={Configuracion} />
    <Route exact strict path="/login" component={Login} />
    <Route exact strict path="/" component={Inicio} />
    {/* <Route exact strict path="/chat" component={Chat} /> */}
    <Route component={Pagina404} />
  </Switch>
);
export default AppRoutes;
