import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./referenciaC.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      concepto: "",
      iconcepto: [],
      listaOn: false,
      activar: "",
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      concepto: "",
      listaOn: false,
      activar: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              codigo: res.data.variable1,
              cedula: res.data.variable2,
              nombre: res.data.variable3,
              motivo: res.data.variable4,
              ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    let data = new FormData();
    data.append("referencia", this.state.concepto);
    data.append("boton", "guardarReferencia");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.buscarReferencia();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Se guardo correctamente.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Ocurrio un problema para guardar el registro.",
          });
        }
      });
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("codigo", e);
      data.append("boton", "eliminarReferencia");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Referencias Contables.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.buscarReferencia();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Referencias Contables.",
            cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el registro.",
          });
        }
      });
    }
  };

  buscarReferencia = () => {
    let data = new FormData();
    data.append("boton", "buscarReferencia");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobanteManual.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable1;

        this.setState({
          iconcepto: xiconcepto,
        });
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarReferencia();
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Menu Referencias Contables. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="contenedorp row">
            <div className="contenedorcedula1x col-4">
              <input
                className="form-control"
                type="text"
                name="concepto"
                id="concepto"
                autoComplete="off"
                placeholder="Referencia"
                maxLength="20"
                onChange={this.onChange.bind(this)}
                value={this.state.concepto.toUpperCase()}
              />
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.guardar.bind(this)}>
                Agregar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc2">REFERENCIA</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.iconcepto.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx1">{item.variable2}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConceptoAction;
