//dependencias
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//estilos
import "./SideBarAction.css";
//componentes
import { outCliente1, outToken } from "../../accion/accion";

class SideBarAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acordion1: false,
      acordion2: false,
      acordion3: false,
      acordion4: false,
      acordion5: false,
      opcionMenu: "",
      menuOn: false,
    };
    this.verificar = this.verificar.bind(this);
  }
  verificar(e) {
    // console.log([e.target.checked])
    if (e.target.checked === true) {
      this.setState({ [e.target.getAttribute("id")]: true });
    } else {
      this.setState({ [e.target.getAttribute("id")]: false });
    }
  }

  cerrarsesion() {
    axios
      .get(
        "https://institutobilinguelasnaciones.com/backend/eliminarToken.php/?pas=" +
          this.props.usuario1.password +
          "&tok=" +
          this.props.token.token
      )
      .then((res) => {
        let tokenp = res.data.tokenEli;

        if (tokenp === true) {
          //console.log('resp-token - '+tokenp)

          this.props.outCliente1();
          this.props.outToken();
        } else {
          //console.log('Usuario no Tiene Token');
        }
      })
      .catch((err) => {
        //console.log('error - '+err.response.statu);
        if (err.response.status === 404) {
          //console.log('el usuario no existe ')
        }
      });
  }
  opmenu1 = () => {
    this.setState({ opcionMenu: "1", menuOn: true });
  };
  opmenu2 = () => {
    this.setState({ opcionMenu: "2", menuOn: true });
  };

  cerrarMenu = () => {
    this.setState({ opcionMenu: "", menuOn: false });
  };

  prueba() {
    return <Redirect to="/medicamento" />;
  }

  render() {
    //console.log('nivel - '+this.props.usuario1.nivel);
    //console.log('usuario: '+this.props.usuario1.usuario);
    return (
      <div>
        <div className="contenedor-menus">
          <div className="menu">
            <ul className="ul">
              <li className="item">
                <span className="btn1">Menu Contabilidad</span>

                <label className="btn" htmlFor="acordion1">
                  <i className="icono izquierda fas fa-file-medical" />
                  Libro de Cuentas
                  {this.state.acordion1 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion1"
                  checked={this.state.acordion1}
                  onChange={this.verificar}
                />

                <div className="smenu" id="informe">
                  <ul>
                    {/* <li><Link to="/pago">Reportar Pago</Link></li> */}
                    <li>
                      <Link to="/libroCuentas">Crear Codigos Contables</Link>
                    </li>
                    {/* <li>
                          <Link to="/pagosrecibidos">Pagos Recibidos Web</Link>
                        </li>
                        <li>
                          <Link to="/pagosaprobados">Pagos Aprobados</Link>
                        </li>
                        <li>
                          <Link to="/mensualidad">Mensualidad General</Link>
                        </li> */}
                    {/* <li>
                            <Link to="/inscripcionG">Inscripcion General</Link>
                          </li> */}
                    {/* <li>
                          <Link to="/canje">Asociar Canjes</Link>
                        </li> */}
                    {/* <li>
                      <Link to="/reportes">Menu de Reportes</Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="item">
                <label className="btn" htmlFor="acordion2">
                  <i className="icono izquierda far fa-edit" />
                  Comprobantes
                  {this.state.acordion2 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion2"
                  checked={this.state.acordion2}
                  onChange={this.verificar}
                />

                <div className="smenu" id="listado">
                  <ul>
                    <li>
                      <Link to="/comprobante">Asiento Contable</Link>
                    </li>
                    {/* <li>
                      <Link to="/adjuntos">Cotejar Documentos</Link>
                    </li>
                    <li>
                      <Link to="/reinscripcion">Reinscripción de Alumnos</Link>
                    </li>
                    <li>
                      <Link to="/correos">Menu de Correos</Link>
                    </li>
                    <li>
                      <Link to="/correosi">Correos Representantes</Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              {/* <li className="item" ><Link to="/espera" className="btn"><i className="icono izquierda fas fa-user-clock"></i>Lista de Espera</Link></li> */}
              <li className="item">
                <label className="btn" htmlFor="acordion3">
                  <i className="icono izquierda fas fa-user-clock" />
                  Reportes
                  {this.state.acordion3 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion3"
                  checked={this.state.acordion3}
                  onChange={this.verificar}
                />

                <div className="smenu" id="listaespera">
                  <ul>
                    <li>
                      <Link to="/reporteContabilidad">Reporte Contable</Link>
                    </li>
                    {/* <li>
                      <Link to="/clientes">Maestro de Clientes</Link>
                    </li>
                    <li>
                      <Link to="/inventario">Maestro de Inventario</Link>
                    </li>
                    <li>
                      <Link to="/facturacion">Facturación</Link>
                    </li>
                    <li>
                      <Link to="/cxcCli">Estado de Cuenta Cliente</Link>
                    </li>
                    <li>
                      <Link to="/reporteInvFac">Reportes</Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="item">
                <label className="btn" htmlFor="acordion4">
                  <i className="icono izquierda fas fa-user-clock" />
                  Consultas Contables
                  {this.state.acordion4 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion4"
                  checked={this.state.acordion4}
                  onChange={this.verificar}
                />

                <div className="smenu" id="listaespera">
                  <ul>
                    <li>
                      <Link to="/gananciaP">Ganancias y Perdidas</Link>
                    </li>
                    <li>
                      <Link to="/balanceG">Balance General</Link>
                    </li>
                    {/* <li>
                      <Link to="/cierreMC">Cierre Contable</Link>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="item">
                <label className="btn" htmlFor="acordion5">
                  <i className="icono izquierda fas fa-user-clock" />
                  Configurar Sistema
                  {this.state.acordion5 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion5"
                  checked={this.state.acordion5}
                  onChange={this.verificar}
                />

                <div className="smenu" id="listaespera">
                  <ul>
                    <li>
                      <Link to="/referenciaC">Referencias Contables</Link>
                    </li>
                    <li>
                      <Link to="/cierreMC">Cierre Contable</Link>
                    </li>
                  </ul>
                </div>
              </li>


              {/* <li className="item">
                <label className="btn" htmlFor="acordion5">
                  <i className="icono izquierda fas fa-folder-open" />
                  Configurar Sistema
                  {this.state.acordion4 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion5"
                  checked={this.state.acordion5}
                  onChange={this.verificar}
                />

                <div className="smenu" id="membrete">
                  <ul>
                    {/* <li><Link to="#" onClick={this.cartas.bind(this)} >Para Cartas</Link></li> 
                    <li>
                      <Link to="/anioescolar">Crear y Activar Año Escolar</Link>
                    </li>
                    <li>
                      <Link to="/usuarios">Crear Usuarios Sistema</Link>
                    </li>
                  </ul>
                </div>
              </li> */}

              {/* <li className="item">
                <Link to="/nomina" className="btn">
                  <i className="icono izquierda far fa-newspaper"></i>Nomina
                  Personal
                </Link>
              </li> */}
              {/* <li className="item">
                    <Link to="/registroCompras" className="btn">
                      <i className="icono izquierda far fa-newspaper"></i>
                      Descripción de Compras
                    </Link>
                  </li> */}

              <li className="item">
                {/* <Link to="#" className="btn"><i className="icono izquierda fas fa-sign-in-alt"></i>Iniciar Sesion</Link> */}
                <Link
                  to="/"
                  className="btn"
                  onClick={this.cerrarsesion.bind(this)}
                >
                  <i className="icono izquierda fas fa-sign-out-alt"></i>
                  Salir al Menu Principal
                </Link>
              </li>
            </ul>
          </div>
          {/* <Chat /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  outCliente1,
  outToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarAction);
