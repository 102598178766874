import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
// import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./reporteCuentaI.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaOn: false,
      mensajealerta: false,
      fecha: "",
      anoEscolar: "",
      fechaDesde: moment().format("YYYY-MM-DD"),
      fechaHasta: moment().format("YYYY-MM-DD"),
      montoB: 0,
      debe: "",
      haber: "",
      saldo: "",
      saldoMesAnterior: "",
      saldoMesAnteriorN: "",
      saldoMesD: "",
      saldoMesH: "",
      banco: "",
      itenConciliacion: [],
      opcedulaP: "",
      mes: [],
      anio: [],
      arreglo1: [],
      arreglo1: [],
      montosIni: [],
      cuenta: [],
      codigo3: "",
      codigo4: "",
      codigo5: "",
      codigo6: "",
      loading: false,
      transitoMesD: "",
      transitoMesH: "",
      saldoIniOn: false,
      montoini: "",
      cuentaContable: "",
    };
  }

  limpiar = () => {
    this.setState({
      montoB: 0,
      debe: "",
      haber: "",
      saldo: "",
      saldoMesAnterior: "",
      saldoMesAnteriorN: "",
      saldoMesD: "",
      saldoMesH: "",
      banco: "",
      mensajealerta: false,
      opcedulaP: "",
      codigo3: "",
      codigo4: "",
      codigo7: "",
      codigo8: "",
      loading: false,
      arreglo1: [],
      arreglo1: [],
      montosIni: [],
      transitoMesD: "",
      transitoMesH: "",
      saldoIniOn: false,
      montoini: "",
      cuentaContable: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "idmateria") this.consultarActividades(e.target.value);
  }
  onChange4 = (e) => {
    let element = e.target;
    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      //console.log(e.target.name);
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("chequeo", e.target.value);
      data.append("idconciliacion", e.target.name);
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("boton", "guardarChequeo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultar();
          } else {
            this.setState({});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  imprimirExcel = () => {
    let data = new FormData();
    data.append("cuentaContable", this.state.cuentaContable);
    data.append("tipofecha", this.state.opcedulaP);
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("fechaDesde", this.state.fechaDesde);
    data.append("fechaHasta", this.state.fechaHasta);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteIndividualCuentaExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "DetalleCuenta.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  imprimirPDF = () => {
    let data = new FormData();
    data.append("cuentaContable", this.state.cuentaContable);
    data.append("tipofecha", this.state.opcedulaP);
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("fechaDesde", this.state.fechaDesde);
    data.append("fechaHasta", this.state.fechaHasta);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteIndividualCuentaPdf.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          // dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  guardarSaldosIniciales = () => {
    let form = new FormData();
    form.append("banco", this.state.banco);
    form.append("mes", this.state.codigo7);
    form.append("anio", this.state.codigo8);
    form.append("monto", this.state.montoini);
    form.append("boton", "guardarSaldosIniciales");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/conciliacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.cargarSaldosIniciales();
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "Se Guardo el Registro con Exito",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Saldo Iniciales",
          cuerpoM: "Ocurrio un error, Verifique por Favor",
        });
      }
    });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  componentDidMount() {
    this.cargarFechas();
    this.cargarCuentas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE INDIVIDUAL POR CUENTA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="botonesConc row">
            <div className="form-group col-12">
              <div className="contenedorcedula1 col-6">
                <select
                  id="cuentaContable"
                  name="cuentaContable"
                  value={this.state.cuentaContable}
                  onChange={this.onChange.bind(this)}
                >
                  <option>Cuenta Contable</option>

                  {this.state.cuenta.map((item, i) => {
                    return (
                      <option key={i} value={item.variable2}>
                        {item.variable6 + " - " + item.variable3}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <div className="radio">
                  <label className="container">
                    Por Fechas
                    <input
                      type="radio"
                      value="PFECHAS"
                      name="opcedulaP"
                      checked={this.state.opcedulaP === "PFECHAS"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="radio">
                  <label className="container">
                    por Mes
                    <input
                      type="radio"
                      value="PMES"
                      name="opcedulaP"
                      checked={this.state.opcedulaP === "PMES"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {this.state.opcedulaP === "PFECHAS" && (
                <div className="botonesConc row">
                  {/* {this.state.opcedulaP === "PFECHAS" && ( */}
                  <div className="contenedorcedula1f col-12">
                    <label>
                      Fecha Desde
                      <input
                        className="form-control"
                        type="date"
                        name="fechaDesde"
                        id="fechaDesde"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fechaDesde}
                      />
                    </label>
                  {/* </div> */}
                  {/* )} */}
                  {/* {this.state.opcedulaP === "PFECHAS" && ( */}
                  {/* <div className="contenedorcedula1f col-7"> */}
                    <label>
                      Fecha Hasta
                      <input
                        className="form-control"
                        type="date"
                        name="fechaHasta"
                        id="fechaHasta"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fechaHasta}
                      />
                    </label>
                    <button
                    className="boton"
                    onClick={this.imprimirPDF.bind(this)}
                  >
                    Imprimir PDF
                  </button>

                  <button
                    className="boton"
                    onClick={this.imprimirExcel.bind(this)}
                  >
                    Imprimir Excel
                  </button>
                  </div>
                  {/* )} */}
                  
                </div>
              )}
              {this.state.opcedulaP === "PMES" && (
                <div className="contenedorcedula1f col-5">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="boton"
                    onClick={this.imprimirPDF.bind(this)}
                  >
                    Imprimir PDF
                  </button>

                  <button
                    className="boton"
                    onClick={this.imprimirExcel.bind(this)}
                  >
                    Imprimir Excel
                  </button>
                </div>
              )}
              <button className="boton" onClick={this.props.salir}>
                Salir
              </button>
              {/* <button className="boton" onClick={this.consultar.bind(this)}>
                Consultar
              </button>
              <button
                className="boton"
                onClick={this.quitarChequeos.bind(this)}
              >
                Quitar Conciliación
              </button>
              <button className="boton" onClick={this.chequeartodo.bind(this)}>
                Conciliar Todos
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
