//dependencias
import React, { Component } from 'react';

//componentes
import CierreMC from './cierreMC'

class Index extends Component {

    render(){
        return(
            <div>
                <CierreMC />
            </div>
        );
    }

}
export default Index;